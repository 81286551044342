
import {props, createAction} from "@ngrx/store";
import {IItemObject} from "../Models/interfaces";
import {NavigationExtras} from "@angular/router";
export const enum ItemActions {
  DecrementQuantity = '[Item] Decrement',
  IncrementQuantity = '[Item] Increment',
  DecrementAutoshipQuantity = '[Item] Autoship Decrement',
  IncrementAutoshipQuantity = '[Item] Autoship Increment',
  UpdateSelectedItem  = '[Item] Update Selected Item',
  DecrementSecondQuantity = '[Second Item] Decrement',
  IncrementSecondQuantity = '[Second Item] Increment',
  DecrementSecondAutoshipQuantity = '[Second Item] Autoship Decrement',
  IncrementSecondAutoshipQuantity = '[Second Item] Autoship Increment',
  UpdateSecondSelectedItem  = '[Second Item] Update Selected Item',
  RouterGo = '[Router] Go',
  LoadItem = '[Item] Load ItemCode',
  LoadSecondItem = '[Second Item] Load ItemCode',
  LoadItemSuccess = '[Item] ItemCode Loaded Success',
  LoadSecondItemSuccess = '[Second Item] ItemCode Loaded Success'
};

export const LoadItem = createAction(
  ItemActions.LoadItem,
  props<{itemCode: string, kit: boolean, currencyCode: string}>(),
)


export const LoadSecondItem = createAction(
  ItemActions.LoadSecondItem,
  props<{itemCode: string, kit: boolean, currencyCode: string}>(),
)


export const IncrementQuantity = createAction(
  ItemActions.IncrementQuantity
);

export const DecrementQuantity = createAction(
  ItemActions.DecrementQuantity
);

export const IncrementAutoshipQuantity = createAction(
  ItemActions.IncrementAutoshipQuantity
);

export const DecrementAutoshipQuantity = createAction(
  ItemActions.DecrementAutoshipQuantity
);

export const UpdateSelectedItem = createAction(
  ItemActions.UpdateSelectedItem,
  props<{selectedItem: IItemObject}>(),
);

export const IncrementSecondQuantity = createAction(
  ItemActions.IncrementSecondQuantity
);

export const DecrementSecondQuantity = createAction(
  ItemActions.DecrementSecondQuantity
);

export const IncrementSecondAutoshipQuantity = createAction(
  ItemActions.IncrementSecondAutoshipQuantity
);

export const DecrementSecondAutoshipQuantity = createAction(
  ItemActions.DecrementSecondAutoshipQuantity
);

export const UpdateSecondSelectedItem = createAction(
  ItemActions.UpdateSecondSelectedItem,
  props<{selectedItem: IItemObject}>(),
);

export const  RouterGo  = createAction(
  ItemActions.RouterGo,
  props< {path: any[],
    queryParams?: any,
    extras?: NavigationExtras;}>(),
);


