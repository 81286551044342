import {OrderDetailRequest} from "./OrderDetailRequest";
import {Customer} from "./Customer";
/**
 * Created by nick on 7/12/2019.
 */
export class EnrollCustomerRequest {
  firstName: string;
  lastName: string;
  source: string;
  company: string;
  phone: string;
  mobilePhone: string;
  email: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  birthday: Date;
  gender: string;
  coApplicantFirstName: string;
  coApplicantLastName: string;
  coApplicantEmail: string;
  taxID: string;
  taxIDType: string;
  subscribeToBroadcasts: boolean;
  docAgreement: boolean;
  webAlias: string;
  password: string;
  shipFirstName: string;
  shipLastName: string;
  shipCompany: string;
  mainAddress1: string;
  mainAddress2: string;
  mainCity: string;
  mainState: string;
  mainZip: string;
  mainCountry: string;
  billFirstName: string;
  billLastName: string;
  billCompany: string;
  mailAddress1: string;
  mailAddress2: string;
  mailCity: string;
  mailState: string;
  mailZip: string;
  mailCountry: string;
  ccNumber: string;
  ccType: number;
  ccCVV: number;
  ccName: string;
  ccExpireMonth: number;
  ccExpireYear: number;
  entryDate: Date;
  customerTypeID: number;
  customerStatus: number;
  notes: string;
  enrollerID: number;
  sponsorID: number;
  subscribeFromIPAddress: string;
  shipMethodID: number;
  priceTypeID: number;
  orderDetails: OrderDetailRequest[];
  createAutoship: boolean;
  autoOrderDetails: OrderDetailRequest[];
  websiteOrderDetails: OrderDetailRequest[];
  annualRenewalDetails: OrderDetailRequest[];
  // DropOrder
  dropShipRecipient: number;
  dropShipDetails: OrderDetailRequest[];
  shippingOverride: number;
  souce: number;
  couponCode: string;
  customerNote: string;
  orderNote: string;
  uevolvSubscription: boolean;
  generateLogin: boolean;
  markOrderAsShipped: boolean;

  setCustomerInformation(c: Customer) {
    this.firstName = c.firstName;
    this.lastName = c.lastName;
    this.email = c.email;
    this.company = c.company;
    this.phone = c.phone;
    this.company = c.company;
    this.mobilePhone = c.mobilePhone;
    this.mailAddress1 = c.mailAddress1;
    this.mailAddress2 = c.mailAddress2;
    this.mailCity = c.mailCity;
    this.mailState = c.mailState;
    this.mailZip = c.mailZip;
    this.mailCountry = c.mailCountry;
    this.mainAddress1 = c.mainAddress1;
    this.mainAddress2 = c.mainAddress2;
    this.mainCity = c.mainCity;
    this.mainState = c.mainState;
    this.mainZip = c.mainZip;
    this.mainCountry = c.mainCountry;
    this.billFirstName = c.billingFirstName;
    this.billLastName = c.billingLastName;
    this.billCompany = c.billingCompany;
    this.couponCode = c.couponCode;
    this.ccNumber = c.ccNumber;
    this.ccType = parseInt(c.ccType);
    this.ccCVV = parseInt(c.ccCVV);
    this.ccName = c.ccName;
    this.ccExpireMonth = parseInt(c.ccExpireMonth);
    this.ccExpireYear = parseInt(c.ccExpireYear);
  }
}
