import { Component, OnInit } from '@angular/core';
// This is DEPRECATED.  Don't bother using it.

@Component({
  selector: 'app-second-product-detail',
  templateUrl: './second-product-detail.component.html',
  styleUrls: ['./second-product-detail.component.scss']
})
export class SecondProductDetailComponent implements OnInit {
  secondProduct: boolean;
  constructor() { }

  ngOnInit() {
    this.secondProduct = false;
  }

}
