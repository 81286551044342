import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs/index";
import {IOrder} from "../../Models/interfaces";
import {
  IShipMethod,
  selectCalculatedAutoship,
  selectCalculatedOrder, selectSelectedShipMethodId,
  selectShipMethods
} from "../../reducers/orderReducer";
import {select, Store} from "@ngrx/store";
import {IState, selectAutoOrderQuantity, selectItemQuantity} from "../../reducers";


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  calculatedOrder$: Observable<IOrder>;
  calculatedAutoship$: Observable<IOrder>;
  shipMethods$: Observable<Array<IShipMethod>>;
  itemQuantity$: Observable<number>;
  autoOrderQuantity$: Observable<number>;
  selectedShipMethodID$: Observable<number>;
  calculatingOrder$: Observable<boolean>;


  constructor(private store: Store<IState>) {
    this.calculatedOrder$ = store.pipe(select(selectCalculatedOrder));
    this.calculatedAutoship$ = store.pipe(select(selectCalculatedAutoship));
    this.shipMethods$ = store.pipe(select(selectShipMethods));
    this.itemQuantity$ = store.pipe(select(selectItemQuantity));
    this.autoOrderQuantity$ = store.pipe(select(selectAutoOrderQuantity));
    this.selectedShipMethodID$ = store.pipe(select(selectSelectedShipMethodId));
    this.calculatingOrder$ = store.pipe(select(s=>s.orderState.calculatingOrder));
  }

  ngOnInit() {
  }

}
