import {ActionReducerMap,  createReducer,  createSelector,  MetaReducer, on} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {RouterReducerState} from "@ngrx/router-store";
import {AddItem, LoadItemCodeSuccess, AddSecondItem, LoadSecondItemCodeSuccess} from "../shop/app.actions";
import {IItemObject, ICustomer} from "../Models/interfaces";
import {ItemObject} from "../Models/ItemObject";
import {IReferrerState, initialReferrerState, referrerReducer} from "./referrerState";
import {
  UpdateSelectedItem, IncrementQuantity, DecrementQuantity, IncrementAutoshipQuantity,
  DecrementAutoshipQuantity, IncrementSecondQuantity, DecrementSecondQuantity, IncrementSecondAutoshipQuantity,
  DecrementSecondAutoshipQuantity, UpdateSecondSelectedItem
} from "../actions/item.actions";
import {
  initialCustomerState, ICustomerState, customerReducer, regionReducer,
  initialRegionState
} from "./customerState";
import {IOrderState, initialOrderState, orderReducer, IShipMethod} from "./orderReducer";
import {UpdateSelectedShipMethod} from "../actions/order.actions";

export const selectReferrerState = (state:IState) => state.referrerState;

export const selectCustomerState = (state:IState) => state.customerState;

export const selectCustomerFormObject = createSelector(
  selectCustomerState,
  (state:ICustomerState) => state.customerForm
)

export const selectItemState = (state: IState) => state.itemState;
export const selectSecondItemState = (state: IState) => state.secondItemState;
export const selectRegionState = (state: IState) => state.regionState;

export const selectItemObject = createSelector(
  selectItemState,
  (state: IItemState) => state.item
);

export const selectSecondItemObject = createSelector(
  selectSecondItemState,
  (state: IItemState) => state.item
);


export const selectSelectedItemObject = createSelector(
  selectItemState,
  (state: IItemState) => state.selectedItem
);
export const selectSecondSelectedItemObject = createSelector(
  selectSecondItemState,
  (state: IItemState) => state.selectedItem
);

export const selectGroupMembers = createSelector(
  selectItemState,
  (state: IItemState) => state.item.groupMembers
);

export const selectSecondGroupMembers = createSelector(
  selectSecondItemState,
  (state: IItemState) => state.item.groupMembers
);

export const selectItemQuantity = createSelector(
  selectItemState,
  (state: IItemState) => state.quantity
);

export const selectSecondItemQuantity = createSelector(
  selectSecondItemState,
  (state: IItemState) => state.quantity
);


export const selectAutoOrderQuantity = createSelector(
  selectItemState,
  (state: IItemState) => state.autoOrderQuantity
);

export const selectSecondAutoOrderQuantity = createSelector(
  selectSecondItemState,
  (state: IItemState) => state.autoOrderQuantity
);

export interface IRegion {
  RegionCode: string;
  RegionName: string;
}

export interface IRegionState {
  countryCode: string;
  regions: IRegion[];
}

export interface IItemState {
  itemCode: string;
  item: IItemObject;
  selectedItem: IItemObject;
  quantity: number;
  autoOrderQuantity: number;
  referrerID: number;

}

export interface IState {
  router? : RouterReducerState;
  itemState: IItemState;
  secondItemState: IItemState;
  referrerState: IReferrerState;
  customerState: ICustomerState;
  orderState: IOrderState;
  regionState: IRegionState;
}

export const initialItemState:IItemState = {
  itemCode: "",
  item: new ItemObject(),
  selectedItem: new ItemObject(),
  quantity: 0,
  autoOrderQuantity:0,
  referrerID: undefined,
}

export const initialSecondItemState:IItemState = {
  itemCode: "",
  item: new ItemObject(),
  selectedItem: new ItemObject(),
  quantity: 0,
  autoOrderQuantity:0,
  referrerID: undefined,
}

export const initialState = {
  itemState: initialItemState,
  secondItemState: initialSecondItemState,
  referrerState: initialReferrerState,
  customerState: initialCustomerState,
  orderState: initialOrderState,
  regionState: initialRegionState,
}

export function getInitialState(): IState {
  return initialState;
}

export const itemReducer = createReducer(
  initialItemState,
  on(AddItem, (state, action) =>
  {
    if (action) {
      let itemCode = action.itemCode;
      return {...state, itemCode: itemCode};
    }
  }),
  on(LoadItemCodeSuccess, (state, action) => {
    let returnItem:any = {...state, item: action.item, itemCode: action.item.itemCode};
    if (action.item.groupMembers && action.item.groupMembers.length >0) {
      returnItem.selectedItem = action.item.groupMembers[0];
    } else {
      returnItem.selectedItem = action.item;
      returnItem.item.groupMembers = [action.item];
    }
    return returnItem;
  }),
  on(IncrementQuantity, (state, action) => {
    console.log('Increment Quantity');
    return {...state, quantity: state.quantity+1};
  }),
  on(DecrementQuantity, (state, action) => {
    console.log('Decrement Quantity');

    return {...state, quantity: state.quantity >0 ? state.quantity-1 : 0};
  }),
  on(IncrementAutoshipQuantity, (state, action) => {
    console.log('Increment Autoship Quantity');
    return {...state, autoOrderQuantity: state.autoOrderQuantity+1};
  }),
  on(DecrementAutoshipQuantity, (state, action) => {
    console.log('Decrement Autoship Quantity');
    return {...state, autoOrderQuantity: state.autoOrderQuantity >0 ? state.autoOrderQuantity-1 : 0};
  }),
  on(UpdateSelectedItem, (state, action) => {
    let newSelectedItem:ItemObject=new ItemObject();
    console.log(state.item.groupMembers.find( (element) => {
      console.log(element.itemID.toString());
      console.log(action.selectedItem.toString())
      element.itemID.toString() == action.selectedItem.toString()
    }));
    return {...state, selectedItem: state.item.groupMembers.find( (element) => {
      return element.itemID == action.selectedItem;
    })};
  }),
  on(UpdateSelectedShipMethod, (state, action) => {
    return {...state, selectedShipMethodID: state.selectedShipMethodID };
  }),
)

export const secondItemReducer = createReducer(
  initialItemState,
  on(AddSecondItem, (state, action) =>
  {
    if (action) {
      let itemCode = action.itemCode;
      return {...state, itemCode: itemCode};
    }
  }),
  on(LoadSecondItemCodeSuccess, (state, action) => {
    let returnItem:any = {...state, item: action.item, itemCode: action.item.itemCode};
    if (action.item.groupMembers && action.item.groupMembers.length >0) {
      returnItem.selectedItem = action.item.groupMembers[0];
    } else {
      returnItem.selectedItem = action.item;
      returnItem.item.groupMembers = [action.item];
    }
    return returnItem;
  }),
  on(IncrementSecondQuantity, (state, action) => {
    console.log('Increment Quantity');
    return {...state, quantity: state.quantity+1};
  }),
  on(DecrementSecondQuantity, (state, action) => {
    console.log('Decrement Quantity');

    return {...state, quantity: state.quantity >0 ? state.quantity-1 : 0};
  }),
  on(IncrementSecondAutoshipQuantity, (state, action) => {
    console.log('Increment Autoship Quantity');
    return {...state, autoOrderQuantity: state.autoOrderQuantity+1};
  }),
  on(DecrementSecondAutoshipQuantity, (state, action) => {
    console.log('Decrement Autoship Quantity');
    return {...state, autoOrderQuantity: state.autoOrderQuantity >0 ? state.autoOrderQuantity-1 : 0};
  }),
  on(UpdateSecondSelectedItem, (state, action) => {
    let newSelectedItem:ItemObject=new ItemObject();
    console.log(state.item.groupMembers.find( (element) => {
      console.log(element.itemID.toString());
      console.log(action.selectedItem.toString())
      element.itemID.toString() == action.selectedItem.toString()
    }));
    return {...state, selectedItem: state.item.groupMembers.find( (element) => {
      return element.itemID == action.selectedItem;
    })};
  }),
  on(UpdateSelectedShipMethod, (state, action) => {
    return {...state, selectedShipMethodID: state.selectedShipMethodID };
  }),
)


export const reducers: ActionReducerMap<IState> = {
    itemState: itemReducer,
  secondItemState: secondItemReducer,
  referrerState: referrerReducer,
  customerState: customerReducer,
  orderState: orderReducer,
  regionState: regionReducer
};

export const metaReducers: MetaReducer<IState>[] = !environment.production ? [] : [];
