/**
 * Created by nick on 7/10/2019.
 */
/**
 * Created by nick on 7/9/2019.
 */

import {props, createAction} from "@ngrx/store";
import {ICustomer} from "../Models/interfaces";
import {EnrollCustomerRequest} from "../Models/EnrollCustomerRequest";
import {EnrollCustomerResponse} from "../Models/EnrollCustomerResponse";
import {EnrollmentSuccessful} from "../Models/EnrollmentSuccessful";
import {RegionsResponse} from "../Models/RegionsResponse";
import {IRegion} from "../reducers/index";
export const enum CustomerActions {
  UpdateCustomer = '[Customer] Update',
  EnrollCustomer = '[Customer] Enroll',
  EnrollCustomerCompleted = '[Customer] Enrollment Completed',
  EnrollmentSuccess = '[Customer] Enrollment Success'
};

export const enum RegionActions {
  UpdateRegions = '[Regions] Update',
  GetRegionsRequest = '[Regions] Get Regions',
  GetRegionsSuccess = '[Regions] Get Regions Success'
}

export const UpdateCustomer = createAction(
  CustomerActions.UpdateCustomer,
  props<{customer: ICustomer, goToEnrollment: boolean}>(),
);

export const UpdateCustomerFormValue = createAction(
  'ngrx/forms/SET_VALUE',
  props<{controlId: string,value:boolean}>(),
);

export const EnrollCustomer = createAction(
  CustomerActions.EnrollCustomer,
  props<{enrollmentRequest: EnrollCustomerRequest}>(),
);

export const EnrollCustomerCompleted = createAction(
  CustomerActions.EnrollCustomerCompleted,
  props<{enrollmentResponse: EnrollCustomerResponse}>(),
);

export const EnrollmentSuccess = createAction(
  CustomerActions.EnrollmentSuccess,
  props<{enrollmentSuccess: EnrollmentSuccessful}>(),
);


export const UpdateRegions = createAction(
  RegionActions.UpdateRegions,
  props<{regions: IRegion[]}>()
)

export const GetRequestRequest = createAction(
  RegionActions.GetRegionsRequest,
  props<{country: string}>()
)

export const GetRegionsSuccess = createAction(
  RegionActions.GetRegionsSuccess,
  props<{regions: IRegion[], countryCode: string}>()
)



