import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {IState} from "../../reducers/index";
import {take} from "rxjs/internal/operators";
import {CustomerActions, RegionActions} from "../../actions/customer.actions";
import {FORM_ID} from "../../reducers/customerState";

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  state$: Observable<any>;
  constructor(private store: Store<IState>) { }

  ngOnInit() {
  }

  chooseCountry(country: string) {
    this.state$ = this.store.select( s=>s) ;
    const theData = this.state$.pipe(take(1));
    theData.subscribe( data=> {
      var customer = data.customerState.customer;
      var customerForm = data.customerState.customerForm;
      customer.mailCountry = country;
      customer.mainCountry = country;
      this.store.dispatch( {type:CustomerActions.UpdateCustomer, customer: customer });
      this.store.dispatch({ type: RegionActions.GetRegionsRequest, country: country})

      this.store.dispatch(
        {
          controlId: FORM_ID+"."+"mainCountry",
          type: 'ngrx/forms/SET_VALUE',
          value: country
        }
      );
      this.store.dispatch(
        {
          controlId: FORM_ID+"."+"mailCountry",
          type: 'ngrx/forms/SET_VALUE',
          value: country
        }
      );

    });
  }
}
