import {Action, createAction, props} from '@ngrx/store';
import {ItemObject} from "../Models/ItemObject";

export const enum AppActions {
  Noop = '[NOOP]',
  NavigateExternal = '[Navigate] Go',
};
export const AddItem = createAction(
  '[Item] Add Item',
  props<{itemCode: string}>(),
)

export const LoadItemCode = createAction(
  '[Item] Load ItemCode',
  props<{itemCode: string}>(),
)

export const LoadSecondItem = createAction(
  '[Second Item] Load ItemCode',
  props<{itemCode: string}>(),
)


export const LoadItemCodeSuccess = createAction(
  '[Item] ItemCode Loaded Success',
  props<{item:ItemObject, kit: boolean}>(),
)

export const NoopAction = createAction(
  AppActions.Noop
)

export const NavigateExternal = createAction(
  AppActions.NavigateExternal,
  props<{path:string}>(),
)

export const AddSecondItem = createAction(
  '[Second Item] Add Item',
  props<{itemCode: string}>(),
)

export const LoadSecondItemCode = createAction(
  '[Second Item] Load ItemCode',
  props<{itemCode: string}>(),
)


export const LoadSecondItemCodeSuccess = createAction(
  '[Second Item] ItemCode Loaded Success',
  props<{item:ItemObject, kit: boolean}>(),
)



